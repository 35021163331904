<template>

  <div class="row" ref="sectionForm">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <ValidationProvider
                  vid="name"
                  rules="required"
                  name="The Name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Name"
                            name="name"
                            fou
                            v-model="formData.name">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="form-group pt-4">
                <label>Is Active</label>&nbsp;
                <l-switch v-model="formData.is_active">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/jobs/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select, TableColumn, Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import GeneralDataTable from "@/components/GeneralDataTable";
import draggable from 'vuedraggable';

extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    [TableColumn.name]: TableColumn,
    FgSelect,
    LSwitch,
    GeneralDataTable,
    PrimeUploader,
    'editor': Editor,
    draggable
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {
        name: "",
        is_active: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    }),
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.sectionForm
    });

    this.id = this.$route.params['id'];
    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Job";
      this.getItem();
    } else {
      this.editMode = false;
      this.formTitle = "Add Job";
      this.loader.hide();
    }

  },


  methods: {
    getItem() {
      let data = {
        id: this.id,
      }
      this.axios.post("jobs/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Job Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("jobs/update/" + this.id, this.formData);
        successMessage = "Job updated successfully";
      } else {
        request = this.axios.post("jobs/create", this.formData);
        successMessage = "Job added successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/jobs/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
  }
}
</script>

<style>

.extra-fields {
  box-shadow: rgb(235 232 232) 1px 1px 20px 0px, rgb(235 232 232) -1px -1px 20px 0px;
  padding: 0 0 3px;
}

.extra-fields > h4 {
  padding: 20px;
}
</style>
